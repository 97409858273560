<template>
    <div class="mainWrapper pb-0">
        <AppLoader v-if="loader" />
        <section class="blockElement space position-relative reatesPage" v-if="show && store.flavordata.flavorId">
            <div class="container">
                <vue-pdf-embed
                    :source="`/assets/pdf/legal/Agreement as to Terms of Service_Global_20240603.pdf`"
                    v-show="!store.flavordata?.isEu" @loaded="Load" />
                <vue-pdf-embed
                    :source="`/assets/pdf/legal/Agreement as to Terms of Service for the use of the Zulutrade Platform Final EU_20240603_FINAL.pdf`"
                    v-show="store.flavordata?.isEu" @loaded="Load" />

            </div>
        </section>
    </div>
</template>
<script>
import { myStore } from "@/store/pinia-store";
import VuePdfEmbed from 'vue-pdf-embed'
export default {
    setup() {
        const store = myStore();
        return { store };
    },
    data() {
        return {
            show: false,
            loader: false
        };
    },
    components: {
        VuePdfEmbed
    },
    methods: {
        Load(e) {
            if (e) {
                this.loader = false
            }
        }
    },
    mounted() {
        setTimeout(() => {
            this.show = true
        }, 1000)
    },
    created() {
        this.loader = true
    }
};
</script>